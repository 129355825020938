<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
            	data:[],
                category:[],
            	heroImage:{}
            }
        },
        async mounted() {
            await this.ready();
            Vue.component("Header",()=>import("@frontend/part/Header.vue"))
            this.$set(this.$root, 'page', this);
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
                SEMICOLON.documentOnReady.init()
            }, 100)
          	
            $(document).ready(() => {
          		AOS.init()
                var $container = $('#demo-gallery');
				$(document).on('click','#portfolio-filter a',function () {
					$('#portfolio-filter li').removeClass('activeFilter');
					$(this).parent('li').addClass('activeFilter');
					var selector = $(this).attr('data-filter');
					$container.isotope('destroy');
					return false;
				});
            })
            this.apiGetHero()
            this.apiGetGalleryList()
        },
        methods: {
        	apiGetGalleryList(){
        		Gen.apirest('/gallery', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.data=resp.data 
                    this.category=resp.category
                    setTimeout(()=>{
                        $('#demo-gallery').isotope('destroy')
                      	SEMICOLON.widget.masonryThumbs();
                        SEMICOLON.initialize.lightbox();
                    },300)
                    this.$root.topProgress.done()
	           })
        	},
            apiGetHero(){
                Gen.apirest('/hero/3', {}, (err, resp)=>{
                    if(err) console.log(err)
                    this.heroImage=resp.data
                })
            },
        	apiGetGalleryCat(id){
        		Gen.apirest('/gallery-cat', {id:id}, (err, resp)=>{
                    if(err) console.log(err)
                    this.data=resp.data
                    setTimeout(()=>{
                        $('#demo-gallery').isotope('destroy')
                        SEMICOLON.widget.masonryThumbs();
                        SEMICOLON.initialize.lightbox();
                    },300)
	           })
        	},
        	clickCat(v){
        		this.apiGetGalleryCat(v)
        	},
        	showAll(){
        		this.apiGetGalleryList()
        	}
        }
    };
</script>
<template>
    <div Content>
      	<Header></Header>
        <section id="slider" class="slider-element slider-parallax swiper_wrapper clearfix sliderActivities">
            <!-- slider-parallax-inner -->
			<div class="paralaxActivities">
				<div v-if="!isMobile" class="swiper-container swiper-parent">
                    <div class="swiper-wrapper" :style="'background-image: url(\''+uploader(heroImage.mhi_image)+'\'); background-size: cover; background-position: top;'">
                        <div class="container clearfix">
                            <div class="slider-caption dark go-mid">
                                <h3>{{heroImage.mhi_title}}</h3>
                                <p class="mt-2">{{heroImage.mhi_desc}}</p>
                            </div>
                        </div>
                        <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                    </div>
                </div><div v-else class="swiper-container swiper-parent">
					<div class="swiper-wrapper" :style="'background-image: url(\''+uploader(heroImage.mhi_image_mobile)+'\'); background-size: cover; background-position: top;'">
						<div class="container clearfix">
							<div class="slider-caption dark go-mid">
								<h3>{{heroImage.mhi_title}}</h3>
                                <p class="mt-2">{{heroImage.mhi_desc}}</p>
							</div>
						</div>
						<div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
					</div>
				</div>
				<a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
			</div>
		</section>
		<section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<h1 class="d-none d-md-block">&nbsp;</h1>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">{{ web.mn_gallery }}</li>
				</ol>
			</div>
		</section>
		<section id="content" class="overflow-none">
			<div class="content-wrap">
				<section class="section nobg nobottommargin notopmargin">
					<div class="container">
						<ul id="portfolio-filter" class="portfolio-filter clearfix">
							<li class="activeFilter">
                                <a href="javascript:;" data-filter="*" @click="showAll()">{{ web.btn_show_all }}</a>
                            </li>
							<li v-for="(c,k) in category">
                                <a href="javascript:;" :data-filter="c.mgc_slug" @click="clickCat(c.mgc_id)">{{c.mgc_category}}</a>
                            </li>
						</ul>
						<div class="clear"></div>
						<div id="demo-gallery" class="masonry-thumbs grid-container grid-4 clearfix" data-big="3" data-lightbox="gallery">
							<a v-for="(v,k) in data" :href="uploader(v.mg_image)" class="" :data-lightbox="'gallery-item'" :class="v.cat">
								<VImg class="image_fade" :src="uploader(v.mg_image)" :alt="v.mg_title" :title="v.mg_title"></VImg>
							</a>
						</div>
					</div>
				</section>
			</div>
		</section>
    </div>
</template>